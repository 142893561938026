@charset "utf-8";



// Our variables
$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;



// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting"
;

.row {
    width:100%;
    display: block;

    // base definition
    .column{
        float:left;
        display: block;
        box-sizing: border-box;
    }
    .full-width {
        @extend .column;
        width:100%;
    }
    .half-width {
        @extend .column;
        @media screen and (min-width: $on-palm) {
            width: 48%;
        }
    }
    .one-third-width {
        @extend .column;
        width: 31.3333333%;
    }
    .one-tenth-width {
        @extend .column;
        width: 10%;
    }
    .nine-tenth-width {
        @extend .column;
        @media screen and (min-width: $on-palm) {
            width: 90%;
        }
    }
    .two-third-width {
        @extend .column;
        @media screen and (min-width: $on-palm) {
            width: 64.6666666%;
        }
    }
    @media screen and (min-width: $on-palm) {
        .left {
            margin-left: 0;
            margin-right: 2%;
        }
        .right {
            margin-left: 2%;
            margin-right: 0;
        }
    }
    &::after{
        content: "";
        display: table;
        clear: both;
    }
}

.caption {
	font-style: italic;
	text-align: center;
	display: block;
}

ul#posts > li > a {
    display: block;
    font-size: 1.17em;
    margin-block-end: .8em;
}
